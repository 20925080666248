import React from 'react';
import {
    ReferenceInput,
    TextInput,
    DateInput,
    AutocompleteInput,
    FormDataConsumer,
    required,
    BooleanInput
} from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import InvoicingMethodField from './InvoicingMethodField';
import SiteReferenceInputWithCreate from '../sites/SiteReferenceInputWithCreate';

const optionText = choice => choice && choice.name ? choice.name : '';
const addressOptionText = choice => `${choice.name ? choice.name : ''} / ${choice.vat ? choice.vat : ''}`;

const packageDefaultValues = () => ({ billing_company_id: 1, invoicing_delivery_method: 'email' });

const PackageCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show" initialValues={packageDefaultValues}>
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <SiteReferenceInputWithCreate
                        {...rest}
                        label="Site"
                        source="site_id"
                        reference="sites"
                        filter={formData.customer_id && { customer_id: formData.customer_id }}
                        key={formData.customer_id}
                        initialValues={formData.customer_id && { customer_id: formData.customer_id }}
                    />
                )}
            </FormDataConsumer>
            <TextInput source="so_number" label="SO Number" />
            <TextInput source="customer_reference" options={{ multiline: true }} rows="4" />
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput label="Billing Address" source="invoicing_address_id" reference="addresses" filter={formData.customer_id && { customer_id: formData.customer_id }} {...rest} validate={required()}>
                        <AutocompleteInput optionText={addressOptionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <DateInput source="start_date" />
            <DateInput source="end_date" />
            <ReferenceInput sort={{ field: 'name', order: 'ASC' }} label="Billing company" source="billing_company_id" reference="companies" allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <InvoicingMethodField />
            <TextInput source="invoice_regroupment_reference" />
            <TextInput source="shipping_address" options={{ multiline: true }} rows="4" />
            <TextInput source="shipping_contact" options={{ multiline: true }} rows="4" />
            <TextInput source="notes" options={{ multiline: true }} rows="4" />
            <TextInput source="support_url" label="Freshdesk URL" />
            <BooleanInput source="is_free" label="Free of charge" />
        </SimpleForm>
    </Create>
);

export default PackageCreate;
