import React from 'react';
import { BooleanInput, TextInput, ReferenceInput, AutocompleteInput, DateInput, FormDataConsumer, required } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import InvoicingMethodField from './InvoicingMethodField';
import StatusInput from './StatusInput';
import SiteReferenceInputWithCreate from '../sites/SiteReferenceInputWithCreate';
import CurrencyInput from '../common/inputs/CurrencyInput';
import VATCodeInput from '../common/inputs/VATCodeInput';

const optionText = choice => choice && choice.name ? choice.name : '';
const addressOptionText = choice => `${choice.name ? choice.name : ''} / ${choice.vat ? choice.vat : ''}`;

const PackageEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <TextInput source="id" disabled />
            <TextInput source="reference" disabled />
            <TextInput source="so_number" label="SO Number" />
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <StatusInput />
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <SiteReferenceInputWithCreate
                        {...rest}
                        label="Site"
                        source="site_id"
                        reference="sites"
                        filter={formData.customer_id && { customer_id: formData.customer_id }}
                        key={formData.customer_id}
                    />
                )}
            </FormDataConsumer>
            <TextInput source="customer_reference" options={{ multiline: true }} rows="4" />
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput label="Billing Address" source="invoicing_address_id" reference="addresses" filter={formData.customer_id && { customer_id: formData.customer_id }} validate={required()} {...rest}>
                        <AutocompleteInput optionText={addressOptionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <ReferenceInput sort={{ field: 'name', order: 'ASC' }} label="Billing company" source="billing_company_id" reference="companies" allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <InvoicingMethodField />
            <TextInput source="invoice_regroupment_reference" />
            <TextInput source="shipping_address" options={{ multiline: true }} rows="4" />
            <TextInput source="shipping_contact" options={{ multiline: true }} rows="4" />
            <DateInput source="start_date" />
            <DateInput source="end_date" />
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput label="Data pool" source="data_pool_id" reference="data_pools" filter={formData.customer_id && { customer_id: formData.customer_id }} {...rest} allowEmpty sort={{ field: 'reference', order: 'ASC' }}>
                        <AutocompleteInput optionText={optionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <DateInput label="Desired Shipment Date" source="desired_date" />
            <TextInput source="notes" options={{ multiline: true }} rows="4" />
            <CurrencyInput source="currency" />
            <VATCodeInput source="default_vat_code" label="Forced VAT" />
            <TextInput source="support_url" label="Freshdesk URL" />
            <BooleanInput source="is_free" label="Free of charge" />
        </SimpleForm>
    </Edit>
);

export default PackageEdit;